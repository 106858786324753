table.DTFC_Cloned tr {
  background-color: white;
  margin-bottom: 0;
}

div.DTFC_LeftHeadWrapper table,
div.DTFC_RightHeadWrapper table {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  background-color: white;
}

div.DTFC_LeftBodyWrapper table,
div.DTFC_RightBodyWrapper table {
  border-top: none;
  margin: 0 !important;
}
div.DTFC_LeftBodyWrapper table thead .sorting:after,
div.DTFC_LeftBodyWrapper table thead .sorting_asc:after,
div.DTFC_LeftBodyWrapper table thead .sorting_desc:after,
div.DTFC_LeftBodyWrapper table thead .sorting:after,
div.DTFC_LeftBodyWrapper table thead .sorting_asc:after,
div.DTFC_LeftBodyWrapper table thead .sorting_desc:after,
div.DTFC_RightBodyWrapper table thead .sorting:after,
div.DTFC_RightBodyWrapper table thead .sorting_asc:after,
div.DTFC_RightBodyWrapper table thead .sorting_desc:after,
div.DTFC_RightBodyWrapper table thead .sorting:after,
div.DTFC_RightBodyWrapper table thead .sorting_asc:after,
div.DTFC_RightBodyWrapper table thead .sorting_desc:after {
  display: none;
}
div.DTFC_LeftBodyWrapper table tbody tr:first-child th,
div.DTFC_LeftBodyWrapper table tbody tr:first-child td,
div.DTFC_RightBodyWrapper table tbody tr:first-child th,
div.DTFC_RightBodyWrapper table tbody tr:first-child td {
  border-top: none;
}

div.DTFC_LeftFootWrapper table,
div.DTFC_RightFootWrapper table {
  border-top: none;
  margin-top: 0 !important;
  background-color: white;
}

div.DTFC_Blocker {
  background-color: white;
}

table.dataTable.table-striped.DTFC_Cloned tbody {
  background-color: white;
}
