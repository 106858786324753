.dropzone,
.dropzone *,
.dropzone-previews,
.dropzone-previews * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.dropzone {
    position: relative;
    border: 1px solid rgba(0,0,0,0.08);
    background: rgba(0,0,0,0.02);
    padding: 1em;
}
.dropzone.dz-clickable {
    cursor: pointer;
}
.dropzone.dz-clickable .dz-message {
    cursor: pointer;
}

.dropzone {
    display: table;
    border: 1px solid rgba(0,0,0,0.03);
    min-height: 335px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: rgba(0,0,0,0.03);
    padding: 23px;
}

.dropzone .dz-message {
    display: table-cell;
    vertical-align: middle;
}
